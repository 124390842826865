<template>
	<div>
		<Header ref="Header" />
		<img :src="menuPic" class="Big-title" />
		<div class="container">
			<div class="personal-list row">
				<div class="personal-list-left">
					<div class="index-sort-tiltle personal-sort-tiltle">个人中心</div>
					<div class="index-sort-list">
						<div v-for="(item,index) in nav">
							<div @click="sortSwitch(index)" :class="sortIndex==index?'index-sort-item2':'index-sort-item'">
								{{item.name}}
							</div>
							<img class="index-sort-boder" v-if="sortIndex==index" src="../../public/images/boder_01.png" />
						</div>
					</div>
				</div>
				<div class="personal-list-right">
					<div class="personal-right-publish">
						<div class="personal-publish-tilte flex">
							<img class="personal-publish-person" src="../../public/images/person_01.png" />
							<div class="personal-publish-smalltile">私人顾问帮您寻找合适场地</div>
						</div>
						<div class="personal-publish-item flex">
							<div class="name">活动城市</div>
							<div class="publish-right">
								<el-select v-model="postData.city" placeholder="活动城市" class="select">
									<el-option v-for="(item,index) in city" :key="index" :label="item.name" :value="item.name">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="personal-publish-item flex">
							<div class="name">活动类型</div>
							<div class="publish-right">
								<el-select v-model="postData.activitiesType" placeholder="举办什么活动" class="select">
									<el-option v-for="(item,index) in activity" :key="index" :label="item.dictLabel" :value="item.dictValue">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="personal-publish-item flex">
							<div class="name">活动日期</div>
							<div class="publish-right">
								<el-date-picker class="publish-right-picker" v-model="period" type="daterange"
									range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
								</el-date-picker>
							</div>
						</div>
						<div class="personal-publish-item flex">
							<div class="name">场地要求</div>
							<div class="linebox row">
								<el-select v-model="showSize" placeholder="空间面积" 
								@change="chooseSize" class="select1">
									<el-option v-for="(item,index) in size" :key="index" :label="item.name"
										:value="item.name">
									</el-option>
								</el-select>
								<el-select v-model="showCapacity" placeholder="容纳人数" 
								@change="chooseCapacity" class="select1">
									<el-option v-for="(item,index) in capacity" :key="index" :label="item.name"
										:value="item.name">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="personal-publish-item flex">
							<div class="name">预算范围</div>
							<div class="publish-right">
								<el-select v-model="showBudget" placeholder="大致预算范围" 
								@change="chooseBudget" class="select">
									<el-option v-for="(item,index) in budget" :key="index" :label="item.name" :value="item.name">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="personal-publish-item flex padding">
							<div class="name">公司名称</div>
							<input class="intext" placeholder="请输入您的所属公司" v-model="postData.company" />
						</div>
						<div class="personal-publish-item flex padding">
							<div class="name">联系人</div>
							<input class="intext" placeholder="请输入联系人姓名" v-model="postData.contact"/>
						</div>
						<div class="personal-publish-item flex padding">
							<div class="name">联系方式</div>
							<input style="width: 120px;" class="intext" maxlength="11" placeholder="电话号码" v-model="postData.tel" />
							<div class="personal-publish-btn jump" style="margin-left: 80px;" @click="getCode()">
								{{tips}}
							</div>
						</div>
						<div class="personal-publish-item flex padding">
							<div class="name">验证码</div>
							<input class="intext" maxlength="4" placeholder="请输入验证码" v-model="postData.captcha"/>
						</div>
						<div class="personal-publish-tips flex">
							<input type="radio" class="personal-publish-radio" :checked="isAgree" @click="check()" />
							<div class="personal-publish-tips-left">我已阅读并同意</div>
							<div class="personal-publish-tips-right jump" @click="toSheetPage('sheetPage')">《兰境网服务使用协议》</div>
						</div>
						<div class="personal-publish-bigbtn jump" @click="publish()">发布</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/components/header.vue';
	import { Notification, MessageBox, Message } from 'element-ui'
	import {
		mapState
	} from 'vuex';
	export default {
		components: {
			Header
		},
		data() {
			return {
				menuPic:'',
				postData:{
					city:'',
					activitiesType:'',
					startTime:'',
					captcha:'',
					endTime:'',
					placeSize:'',
					placeCapacity:'',
					budget:'',
					company:'',
					contact:'',
					tel:'',
					captcha:'',
				},
				showSize:'',
				showCapacity:'',
				showBudget:'',
				sortIndex: 1,
				isAgree: null,
				period: '',
				time:60,
				tips:'获取验证码',
				city: [],
				activity: [],
				size: [
					{
						name: '不限',
						sizeMin: 0,
						sizeMax: 0
					},
					{
						name: '100㎡以下',
						sizeMin: 0,
						sizeMax: 100
					},
					{
						name: '100㎡—300㎡',
						sizeMin: 100,
						sizeMax: 300
					},
					{
						name: '300㎡—500㎡',
						sizeMin: 300,
						sizeMax: 500
					},
					{
						name: '500㎡—1000㎡',
						sizeMin: 500,
						sizeMax: 1000
					},
					{
						name: '1000㎡—1500㎡',
						sizeMin: 1000,
						sizeMax: 1500
					},
					{
						name: '1500㎡',
						sizeMin: 1500,
						sizeMax: 0
					},
				],
				capacity: [
					{
						name: '不限',
						capacityMin: 0,
						capacityMax: 0,
					},
					{
						name: '20人以下',
						capacityMin: 0,
						capacityMax: 20,
					},
					{
						name: '20-50人',
						capacityMin: 20,
						capacityMax: 50,
					},
					{
						name: '50-100人',
						capacityMin: 50,
						capacityMax: 100,
					},
					{
						name: '100-300人',
						capacityMin: 100,
						capacityMax: 300,
					},
					{
						name: '300-500人',
						capacityMin: 300,
						capacityMax: 500,
					},
					{
						name: '500人以上',
						capacityMin: 500,
						capacityMax: 0,
					}
				],
				budget:[
					{name:'3000以下',budgetMin:0,budgerMax:3000},
					{name:'3-5千',budgetMin:3000,budgerMax:5000},
					{name:'5-8千',budgetMin:5000,budgerMax:8000},
					{name:'8千-1.2万',budgetMin:8000,budgerMax:12000},
					{name:'1.2万-1.5万',budgetMin:12000,budgerMax:15000},
					{name:'1.5-2万',budgetMin:15000,budgerMax:20000},
					{name:'2-3万',budgetMin:20000,budgerMax:30000},
					{name:'3-5万',budgetMin:30000,budgerMax:50000},
					{name:'5-8万',budgetMin:50000,budgerMax:80000},
					{name:'8-12万',budgetMin:80000,budgerMax:120000},
					{name:'12-20万',budgetMin:120000,budgerMax:200000},
					{name:'20-30万',budgetMin:200000,budgerMax:300000},
					{name:'30-50万',budgetMin:300000,budgerMax:500000},
					{name:'50万以上',budgetMin:500000,budgerMax:0},
				],
				nav:[
					{name:'个人信息',href:'personal'},
					{name:'发布需求',href:'publish'},
					{name:'我的订单',href:'myOrder'},
					{name:'我的发布',href:'myPublish'},
					{name:'我的收藏',href:'myCollect'},
					{name:'我的消息',href:'myNews'},
					{name:'意见反馈',href:'feedback'},
				],
			}
		},
		computed: {
			...mapState(['userInfo']),
		},
		mounted() {
			//判断用户是否登录
			if(!this.userInfo.id){
				this.$refs.Header.showLayer('login');
			}
			this.postData.company=this.userInfo.company;
			this.postData.contact=this.userInfo.name;
			this.postData.tel=this.userInfo.tel;
			this.$refs.Header.setIndex(-1);
			// 获取菜单图片
			this.$api.getMenuPic('发布需求').then(res=>{
				console.log(res,'菜单图片');
				this.menuPic=res.data[0].picture;
			})
			// console.log(this.userInfo,'用户信息')
			// 获取城市列表
			this.getCities();
			// 获取活动类型列表
			this.$api.getDictType('app_activities_type').then(res=>{
				console.log(res,'活动类型列表');
				this.activity=res.rows;
			})
		},
		methods: {
			sortSwitch(index){
				this.sortIndex=index;
				this.$router.push({path:this.nav[index].href});
			},
			// 获取城市列表
			getCities(){
				var data={
					orderByColumn:'hot',
					isAsc:'desc'
				}
				// console.log(data,'城市传参')
				this.$api.getCityList(data).then(res => {
					console.log(res,'城市列表')
					this.city = res.data.city;
				});
			},
			// 协议详情
			toSheetPage(page) {
				this.$router.push({
					path: page,
					query:{
						categoryCode:6,
					}
				});
			},
			// 选择面积
			chooseSize(e){
				console.log(e);
				for(let i=0;i<this.size.length;i++){
					if(e===this.size[i].name){
						this.postData.placeSize=this.size[i].sizeMax;
					}
				}
			},
			// 选择容量
			chooseCapacity(e){
				console.log(e);
				for(let i=0;i<this.capacity.length;i++){
					if(e===this.capacity[i].name){
						this.postData.placeCapacity=this.capacity[i].capacityMax;
					}
				}
			},
			// 选择预算
			chooseBudget(e){
				for(let i=0;i<this.budget.length;i++){
					if(e===this.budget[i].name){
						this.postData.budget=this.budget[i].budgerMax;
					}
				}
				console.log(this.postData.budget)
			},
			check() {
				this.isAgree = this.isAgree == null ? 1 : null;
			},
			getCode(){
				if(this.time==60){
					if (!(/^1[3|4|5|8|6|7|9][0-9]\d{8}$/.test(this.postData.tel))) {
						alert('请输入可以联系到您的电话号码');
						return;
					}
					this.$api.getSms(this.postData.tel).then(res=>{
						console.log(res,'验证码返回');
						var timer= setInterval(res=>{
							this.time=this.time-1;
							this.tips=this.time+'s后重新获取'
							// console.log(this.time);
							if(this.time<=0){
								clearInterval(timer);
								this.time=60;
								this.tips='获取验证码';
							}
						},'1000')
					});
				}else{
					console.log('请等待计时')
				}
			},
			publish(){
				if(this.isAgree==null){
					alert('请同意服务协议');
					return;
				}
				if (!(/^1[3|4|5|8|6|7|9][0-9]\d{8}$/.test(this.postData.tel))) {
					this.$alert('请输入可以联系到您的电话号码','系统提示')
					// alert('');
					return;
				}
				if(this.postData.captcha.length<4){
					this.$alert('验证码填写错误','系统提示')
					return;
				}
				if(this.postData.city=='' || this.postData.activitiesType=='' || this.period=='' ||
					this.postData.company=='' || this.postData.contact==''){
					this.$alert('请填写完整信息','系统提示')
					return;
				}
				// if(this.postData.activitiesType==''){
				// 	this.$alert('请选择活动类型','系统提示')
				// 	return;
				// }
				// if(this.period==''){
				// 	this.$alert('请选择活动时间','系统提示')
				// 	return;
				// }
				console.log(this.period,'时间时间');
				var startTime=new Date(this.period[0]);
				this.postData.startTime=startTime.getFullYear()+'-'+(startTime.getMonth()+1)+'-'+startTime.getDate();
				var endTime=new Date(this.period[1]);
				this.postData.endTime=endTime.getFullYear()+'-'+(endTime.getMonth()+1)+'-'+endTime.getDate();
				console.log(this.postData,'当前传参');
				this.$api.publish(this.postData).then(res=>{
					console.log(res,'发布返回');
					if(res){
						this.$router.push({path:'myPublish'})
						this.$alert('发布成功','系统提示')
					}
				})
			},
		},
	};
</script>
<style scoped>
	/deep/.el-input__inner {
		background-color: #f8f8f8;
		border: 0px solid #000000;
	}

	/deep/.el-range-input {
		background-color: #f8f8f8;
		/* border: solid 1px; */
		margin-left: 10px;
	}

	.select {
		min-width: 150px;
		margin: 5px auto 0 auto;
		margin-left: 100px;
		/* border: solid 1px; */
	}

	.linebox {
		width: 350px;
		margin-left: 100px;
		justify-content: space-between;
	}

	.select1 {
		width: 150px;
		margin-top: 5px;
	}

	.intext {
		width: 300px;
		margin-top: 5px;
		margin-left: 115px;
		background-color: #f8f8f8;
	}

	.name {
		font-size: 18px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #666666;
		width: 74px;
		margin-left: 45px;
	}

	.padding {
		padding-bottom: 10px;
	}
</style>
